import produce from 'immer';
import groupArrayBy from 'lodash/groupBy';
import { useMemo } from 'react';

import type { AssetGrouping } from 'model/AssetGrouping';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { FilterOption } from 'utils/useDataManipulation/useEntityFiltering';

import useAllocations from './useAllocations';

type GroupFilterOption = FilterOption<{ entity: string }>;

export function getKeyByGrouping(groupBy: AssetGrouping) {
  switch (groupBy) {
    default:
    case 'asset-breakdown':
      return 'instrumentType';
    case 'country':
      return 'country';
    case 'account':
      return 'fullNameAccount';
    case 'custodian':
      return 'custodianReporting';
    case 'entity':
      return 'entity';
    case 'sector':
      return 'sector';
  }
}

export default function useAllocationGroups({
  groupBy,
  portfolio,
  subselection,
  multipleOptionsEntities,
}: {
  groupBy: AssetGrouping;
  portfolio: string | undefined;
  subselection: string | undefined;
  multipleOptionsEntities?: GroupFilterOption[];
}) {
  const { data } = useAllocations(portfolio);

  const valueOptionsEntities =
    multipleOptionsEntities && multipleOptionsEntities.map((f) => f.label);

  const filteredByEntities = useMemo(
    () =>
      data &&
      valueOptionsEntities &&
      valueOptionsEntities?.length > 0 &&
      !valueOptionsEntities.includes('All')
        ? [...data].filter((d) => valueOptionsEntities.includes(d.entity))
        : data,
    [valueOptionsEntities, data],
  );

  const getNameBySlug = useMemo(() => {
    const groupings = [
      'asset-breakdown',
      'custodian',
      'account',
      'entity',
      'country',
      'sector',
    ] as const;

    const nameMaps = (filteredByEntities ?? []).reduce(
      (acc, asset) =>
        produce(acc, (next) => {
          groupings.forEach((grouping) => {
            const key = getKeyByGrouping(grouping);
            const nameMap = next[grouping] ?? {};

            nameMap[kebabCaseCustom(asset[key])] = asset[key];

            next[grouping] = nameMap;
          });
        }),
      {} as Record<
        AssetGrouping,
        Record<string, string | undefined> | undefined
      >,
    );

    return (grouping: AssetGrouping, slug: string): string =>
      nameMaps[grouping]?.[slug] ?? '';
  }, [filteredByEntities]);

  const grouped = useMemo(() => {
    /*
     * If we have a subselection (i.e. we are browsing by country > United
     * States) then we want to show the asset breakdown but for only US assets.
     *
     * Hence we consider "groupBy" for selecting assets but not for grouping.
     */
    const groupingKey = getKeyByGrouping(
      subselection ? 'asset-breakdown' : groupBy,
    );
    const selectingKey = getKeyByGrouping(groupBy);

    return Object.entries(
      groupArrayBy(filteredByEntities, (asset) => asset[groupingKey]),
    )
      .map(([groupName, assets]) => {
        const selectedAssets = subselection
          ? assets.filter(
              (asset) => kebabCaseCustom(asset[selectingKey]) === subselection,
            )
          : assets;

        const selectedAssetsWithPO = () =>
          selectedAssets.filter((a) => !a.closedPosition);

        // The same asset might appear multiple times because they come from
        // different custodians or entities therefore the back treats it as different
        // assets. But to the user we show it only once.
        const totalAssets = Object.entries(
          groupArrayBy(selectedAssetsWithPO(), (asset) => asset.fullName),
        ).length;

        // noinspection ES6DestructuringVariablesMerge
        const { totalAllocation, totalValue } = selectedAssetsWithPO().reduce(
          (
            { totalAllocation: currentAllocation, totalValue: currentValue },
            asset,
          ) => ({
            totalAllocation: currentAllocation + asset.allocation,
            totalValue: currentValue + asset.value,
          }),
          { totalAllocation: 0, totalValue: 0 },
        );

        return {
          id: `by_${groupBy}_${kebabCaseCustom(groupName)}`,
          name: groupName,
          slug: kebabCaseCustom(groupName),
          totalAllocation,
          totalAssets,
          totalValue,
        };
      })
      .filter((group) => group.totalAssets > 0);
  }, [filteredByEntities, groupBy, subselection]);

  return {
    data: grouped,
    getNameBySlug,
  };
}

export type AllocationGroup = NonNullable<
  ReturnType<typeof useAllocationGroups>['data']
>[number];
