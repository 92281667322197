import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import DefaultAmount from 'components/Amount';
import DefaultButton from 'components/Button';
import CashStats from 'components/CashStats';
import Select from 'components/Select';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import from from 'styles/responsive';

export const Container = styled(PageDetailLayout)``;

export const Content = styled.div`
  padding: 2rem 1.25rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportToExcelButton = styled(DefaultButton)`
  min-width: 8.625rem;
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-right: 1em;
`;

export const FilterButton = styled(SortButton)``;

export const Header = styled.div``;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.925rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.laptop`
    margin-bottom: 0.25rem;
  `}
`;

export const Amount = styled(DefaultAmount).attrs({ forwardedAs: 'h1' })`
  margin: 0;
  font-size: 1.75rem;
  line-height: 2.45rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  ${from.tablet`
    margin-bottom: 2.125rem;
  `}
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const Button = styled(DefaultButton)`
  margin-right: 1rem;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 0.125rem;
`;

export const StatsTitle = styled.h2`
  font-size: 1rem;
  line-height: 1.4;
`;

export const Stats = styled(CashStats)`
  margin-bottom: 2rem;
  ${from.tablet`
    margin-bottom: 2.5rem;
  `}
`;
