import { FC, memo, useMemo } from 'react';

import BaseStats from 'components/BaseStats';
import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import { ButtonTextContainer } from './styles';
import type { Props } from './types';

const CashStats: FC<Props> = ({
  cashUSD,
  cashOther,
  cashEquivalent,
  className,
  pathname,
  totalCashAvailable,
  USTreasuries,
  totalLiquidityAvailable,
  totalCashAllocation,
}) => {
  const relativeTotalLiquidityAvailable =
    totalCashAvailable === 0
      ? 0
      : (totalLiquidityAvailable * (totalCashAllocation * 100)) /
        (totalCashAvailable * 100);

  const cols = useMemo(
    () => [
      {
        id: 1,
        title: 'Cash (USD)',
        value: formatAmount(cashUSD),
      },
      {
        id: 2,
        title: 'Cash (Other Currencies)',
        value: formatAmount(cashOther),
      },
      {
        id: 3,
        title: 'Cash Equivalent',
        value: formatAmount(cashEquivalent),
      },
      {
        id: 4,
        title: 'Total Cash Available',
        value: formatAmount(totalCashAvailable),
      },
      {
        id: 5,
        title: 'US Treasuries',
        value: formatAmount(USTreasuries),
      },
      {
        id: 6,
        title: 'Total Liquidity Available',
        value: formatAmount(totalLiquidityAvailable),
        subValue: formatPercentage(relativeTotalLiquidityAvailable),
      },
    ],
    [
      cashUSD,
      cashOther,
      cashEquivalent,
      totalCashAvailable,
      USTreasuries,
      totalLiquidityAvailable,
      relativeTotalLiquidityAvailable,
    ],
  );

  return (
    <BaseStats
      className={className}
      cols={cols}
      pathname={pathname}
      buttontext={
        <ButtonTextContainer>
          Liquidity Available
          <span>{formatAmount(totalLiquidityAvailable)}</span>
        </ButtonTextContainer>
      }
    />
  );
};

export default memo(CashStats);
