import { useLocation, useRouteMatch } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';
import type { KeySortOfColumn } from 'utils/sort/types';

import {
  useAssets,
  useCurrentGrouping,
  useSorting,
} from '../CategoryDetail/logic';
import { useSummary } from '../CategoryDetailCash/logic';
import {
  useData,
  useFilteringEntities,
  useSortingLiquidityTable,
} from './logic';

export default function useConnect() {
  const categorySlug = 'cash';
  const { url } = useRouteMatch();
  const { selectedPortfolio } = useSelectedClient();
  const { search } = useLocation();
  const grouping = useCurrentGrouping({ search });
  const { compare } = useSorting();
  const {
    currentSorting,
    onChangeSorting,
    compare: compareLiquidityTable,
  } = useSortingLiquidityTable();

  const {
    currentFiltering,
    multipleOptions,
    filterOptions,
    onChangeFiltering,
  } = useFilteringEntities({
    portfolio: selectedPortfolio?.id,
    grouping,
  });

  const { balance, assets } = useAssets({
    categorySlug,
    compare,
    grouping,
    multipleEntitiesOptions: multipleOptions,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const totalCashAllocation = assets.reduce(
    (total, item) => item.allocation + total,
    0,
  );

  const { accounts, mobileRows } = useData({
    portfolio: selectedPortfolio?.id,
    grouping,
    multipleEntitiesOptions: multipleOptions,
    totalCashAllocation,
    compare: compareLiquidityTable,
  });

  const summary = useSummary({
    accounts,
  });

  const parentUrl = up(url);

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${accounts?.length} Accounts`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Cash (USD)',
      keyOrderAsc: 'cashUsdASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Cash (Other)',
      keyOrderAsc: 'cashOtherASC',
      keyOrderDesc: 'cashOtherDESC',
    },
    {
      titleColumn: 'Cash Equivalent',
      keyOrderAsc: 'cashEquivASC',
      keyOrderDesc: 'cashEquivDESC',
    },
    {
      titleColumn: 'US Treasuries',
      keyOrderAsc: 'usTreasuriesASC',
      keyOrderDesc: 'usTreasuriesDESC',
    },
    {
      titleColumn: 'Liquidity Available',
      keyOrderAsc: 'totalLiquidityASC',
      keyOrderDesc: 'totalLiquidityDESC',
    },
  ];

  return {
    accounts,
    totalCashAllocation,
    balance,
    mobileRows,
    parentUrl,
    summary,
    handle: {
      changeSorting: onChangeSorting,
      changeFiltering: onChangeFiltering,
    },
    url,
    search,
    filterOptions,
    currentFiltering,
    currentSorting,
    headerColumns,
  };
}
