import { useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import type { KeySortOfColumn } from 'utils/sort/types';
import useModal from 'utils/useModal';

import {
  useCurrentGrouping,
  useFiltering,
  useFilteringEntities,
  useGroupLinks,
  useGroups,
  useSorting,
} from './logic';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const { closeModal, modalOpen, openModal } = useModal();
  const { selectedPortfolio } = useSelectedClient();
  const { groupBy, subselection } = useCurrentGrouping({ search });

  const {
    currentFiltering: currentFilteringEntities,
    multipleOptions: multipleOptionsEntities,
    filterOptions: filterOptionsEntities,
    onChangeFiltering: onChangeFilteringEntities,
  } = useFilteringEntities({
    portfolio: selectedPortfolio?.id,
    grouping: { groupBy, subselection },
  });

  const { onChangeFiltering } = useFiltering();

  useEffect(() => {
    if (groupBy !== 'account') {
      onChangeFilteringEntities('default');
    }
  }, [groupBy, onChangeFilteringEntities]);

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
    multipleOptionsEntities,
  });
  const { backUrl, breadcrumbs, groupLinks, pageTitle, selectedGroupUrl } =
    useGroupLinks({
      getNameBySlug,
      groupBy,
      search,
      subselection,
      url,
    });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const { allocationChartData, balance, groups, allocation } = useGroups({
    compare,
    groupBy,
    portfolio: selectedPortfolio?.id,
    search,
    subselection,
    multipleOptionsEntities,
    url,
  });

  const totalAssets = useMemo(
    () => groups.reduce((acc, item) => item.totalAssets + acc, 0),
    [groups],
  );

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${totalAssets} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Total ($)',
      keyOrderAsc: 'totalASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalPorcASC',
      keyOrderDesc: 'totalPorcDESC',
    },
  ];

  const isBreakdown = groupBy === 'asset-breakdown';

  const hasEntityFilter = groupBy === 'account' && !subselection;

  return {
    allocationChartData,
    backUrl,
    balance,
    allocation,
    breadcrumbs,
    filterOptionsEntities,
    currentFilteringEntities,
    currentSorting,
    groupLinks,
    groups,
    handle: {
      changeFilteringEntities: onChangeFilteringEntities,
      changeFiltering: onChangeFiltering,
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
    },
    isBreakdown,
    hasEntityFilter,
    pageTitle,
    selectedGroupUrl,
    sortOptions,
    headerColumns,
    modalOpen,
    url,
  };
}
