import styled from 'styled-components';

import AllocationsHeader from 'components/AllocationsHeader';
import DefaultButton from 'components/Button';
import SectionNavigation from 'components/SectionNavigation';
import { Link } from 'components/SectionNavigation/styles';
import Select from 'components/Select';
import MainContentGrid from 'containers/Common/MainContentGrid';
import from from 'styles/responsive';

export const Container = styled(MainContentGrid)``;

export const Header = styled(AllocationsHeader)``;

export const Navigation = styled(SectionNavigation)`
  padding-top: 1rem;
  ${Link} {
    order: 0;
  }
`;

export const Content = styled.div``;

export const Toolbar = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  ${from.tablet`
    display: flex;
    justify-content: flex-end;
  `}
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-left: 1rem;
`;

export const FilterButton = styled(SortButton)``;

export const Button = styled(DefaultButton)`
  min-width: 8.625rem;
  margin-left: 1rem;
`;
