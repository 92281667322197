import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import type { Asset } from 'api/hooks/allocations/useAllocationAssets';
import useAllocationAssets from 'api/hooks/allocations/useAllocationAssets';
import { groupingLabels } from 'containers/Protected/Allocations/AllocationsRoot/logic';
import type { AssetGrouping } from 'model/AssetGrouping';
import { isValidGrouping } from 'model/AssetGrouping';
import up from 'utils/paths';
import searchWithoutSort from 'utils/searchWithoutSort';
import type { FilterOption } from 'utils/useDataManipulation/useEntityFiltering';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

type AssetFilterOption = FilterOption<Asset>;
type AssetSortOption = SortOption<Asset>;

export function useAssets({
  categorySlug,
  compare,
  filter,
  multipleEntitiesOptions,
  grouping,
  portfolio,
  url,
}: {
  categorySlug: string;
  compare: AssetSortOption['compare'];
  filter?: (asset: Asset) => boolean;
  grouping: ReturnType<typeof useCurrentGrouping>;
  multipleEntitiesOptions?: AssetFilterOption[];
  portfolio: string | undefined;
  url: string;
}) {
  const searchParams = useLocation().search;
  const { data } = useAllocationAssets({
    categorySlug,
    grouping,
    multipleEntitiesOptions,
    portfolio,
  });

  const allocationChartData = useMemo(() => {
    const sorted = [...data]
      .sort((a, b) => b.allocation - a.allocation)
      .map(({ allocation, value, ...asset }) => ({
        ...asset,
        totalAllocation: allocation,
        totalValue: value,
      }));

    const relevant = sorted.slice(0, 14);
    const others = sorted.slice(14);

    return [
      ...relevant,
      ...(others.length >= 2
        ? [
            others.reduce(
              (acc, group) => ({
                ...acc,
                totalAllocation: acc.totalAllocation + group.totalAllocation,
                totalValue: acc.totalValue + group.totalValue,
              }),
              {
                id: 'other',
                name: 'Other',
                totalAllocation: 0,
                totalValue: 0,
              },
            ),
          ]
        : others),
    ];
  }, [data]);

  const allocation = useMemo(
    () => data.reduce((acc, asset) => acc + asset.allocation, 0),
    [data],
  );

  const sorted = useMemo(
    () => (compare ? [...data].sort(compare) : data),
    [compare, data],
  );

  const filtered = useMemo(
    () => (filter ? [...sorted].filter(filter) : sorted),
    [filter, sorted],
  );

  const assets = useMemo(
    () =>
      filtered.map((asset) => ({
        ...asset,
        pathname: `${url}/assets/${asset.slug}${searchParams}`,
      })),
    [filtered, url, searchParams],
  );

  const balance = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.value, 0),
    [assets],
  );

  return {
    allocation,
    allocationChartData,
    assets,
    balance,
  };
}

export function useCurrentGrouping({ search }: { search: string }) {
  return useMemo(():
    | { groupBy: AssetGrouping; subselection: string }
    | undefined => {
    const { browseBy, subselection } = parse(search);

    if (
      typeof browseBy === 'string' &&
      isValidGrouping(browseBy) &&
      typeof subselection === 'string'
    ) {
      return { groupBy: browseBy, subselection };
    }

    return undefined;
  }, [search]);
}

export function useCurrentSubtypes({ search }: { search: string }) {
  return useMemo((): string[] | undefined => {
    const { subtypes } = parse(search);

    if (typeof subtypes === 'string') {
      return subtypes.split(',');
    }

    return undefined;
  }, [search]);
}

export function useNavigationLinks({
  categorySlug,
  getNameBySlug,
  grouping,
  search,
  url,
}: {
  categorySlug: string;
  getNameBySlug: (grouping: AssetGrouping, slug: string) => string;
  grouping: ReturnType<typeof useCurrentGrouping>;
  search: string;
  url: string;
}) {
  const parentUrl = up(url, 2);
  const searchAux = searchWithoutSort(search);

  const getGrouping = useCallback(
    (groupBy: AssetGrouping) => ({
      name: groupingLabels[groupBy],
      value: `${parentUrl}?${stringify({
        ...parse(searchAux),
        browseBy: groupBy,
        subselection: undefined,
      })}`,
    }),
    [parentUrl, searchAux],
  );

  // The current search without the grouping information
  const cleanSearch = useMemo(() => {
    const result = stringify({
      ...parse(searchAux),
      browseBy: undefined,
      subselection: undefined,
    });

    return result.length > 0 ? `?${result}` : '';
  }, [searchAux]);

  const pageTitle = getNameBySlug('asset-breakdown', categorySlug);

  const breadcrumbs = useMemo(
    () => [
      ...(grouping
        ? [
            getGrouping(grouping.groupBy),
            {
              name: getNameBySlug(grouping.groupBy, grouping.subselection),
              value: `${parentUrl}?${stringify({
                ...parse(searchAux),
                browseBy: grouping.groupBy,
                subselection: grouping.subselection,
              })}`,
            },
          ]
        : [{ name: 'Asset Breakdown', value: `${parentUrl}${searchAux}` }]),
      {
        name: pageTitle,
        value: url,
      },
    ],
    [
      getGrouping,
      getNameBySlug,
      grouping,
      pageTitle,
      parentUrl,
      searchAux,
      url,
    ],
  );

  const navigationLinks = useMemo(
    () =>
      [
        { name: 'Asset Breakdown', value: `${parentUrl}${cleanSearch}` },
        getGrouping('custodian'),
        getGrouping('account'),
        getGrouping('entity'),
        getGrouping('country'),
        getGrouping('sector'),
      ].filter((link) => link.value !== breadcrumbs[0]?.value),
    [breadcrumbs, cleanSearch, getGrouping, parentUrl],
  );

  const selectedUrl = url;

  const backUrl = `${parentUrl}${search}`;

  return { backUrl, breadcrumbs, navigationLinks, pageTitle, selectedUrl };
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => b.value - a.value,
        label: 'Total: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.value - b.value,
        label: 'Total: Low to High',
        value: 'totalASC',
      },
      {
        compare: (a, b) => b.allocation - a.allocation,
        label: 'Total %: High to Low',
        value: 'totalPorcDESC',
      },
      {
        compare: (a, b) => a.allocation - b.allocation,
        label: 'Total %: Low to High',
        value: 'totalPorcASC',
      },
      {
        compare: (a, b) => b.shares - a.shares,
        label: 'Shares: High to Low',
        value: 'sharesDESC',
      },
      {
        compare: (a, b) => a.shares - b.shares,
        label: 'Shares: Low to High',
        value: 'sharesASC',
      },
      {
        compare: (a, b) => b.marketPrice - a.marketPrice,
        label: 'Market Price: High to Low',
        value: 'marketPriceDESC',
      },
      {
        compare: (a, b) => a.marketPrice - b.marketPrice,
        label: 'Market Price: Low to High',
        value: 'marketPriceASC',
      },
      {
        compare: (a, b) => b.marketValue - a.marketValue,
        label: 'Market Value: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.marketValue - b.marketValue,
        label: 'Market Value: Low to High',
        value: 'marketValueASC',
      },
      {
        compare: (a, b) => b.cost - a.cost,
        label: 'Cost: High to Low',
        value: 'costDESC',
      },
      {
        compare: (a, b) => a.cost - b.cost,
        label: 'Cost: Low to High',
        value: 'costASC',
      },
      {
        compare: (a, b) => b.dividendsReceived - a.dividendsReceived,
        label: 'Dividends Received: High to Low',
        value: 'dividendsReceivedDESC',
      },
      {
        compare: (a, b) => a.dividendsReceived - b.dividendsReceived,
        label: 'Dividends Received: Low to High',
        value: 'dividendsReceivedASC',
      },
      {
        compare: (a, b) => b.totalProfitAndLoss - a.totalProfitAndLoss,
        label: 'P&L ($): High to Low',
        value: 'pandlDESC',
      },
      {
        compare: (a, b) => a.totalProfitAndLoss - b.totalProfitAndLoss,
        label: 'P&L ($): Low to High',
        value: 'pandlASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}
