import { useCallback, useEffect, useState } from 'react';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useClientTransactions from 'api/hooks/transactions/useClientTransactions';
import useGetMinimumTransactionDate from 'api/hooks/transactions/useGetMinimumTransactionDate';
import type { ClientTransactionDatum } from 'model/ClientTransaction';
import type { KeySortOfColumn } from 'utils/sort/types';

import type { TypeTransactions } from './ControlTabType/types';
import {
  applyAccountFilter,
  applyAssetClassFilter,
  applyCategoryFilter,
  applyEntityFilter,
  applySearchFilter,
  applyTypeFilter,
  getAccountsOptions,
  getAssetsClassOptions,
  getBeginDateAndEndDate,
  getCategoriesOptions,
  getEntitiesOptions,
  useSorting,
} from './logic';
import { FilterDate } from './types';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();
  const { data: minTransactionDate } = useGetMinimumTransactionDate(
    selectedPortfolio?.id ?? undefined,
  );

  const { currentSorting, onChangeSorting, compare } = useSorting();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<FilterDate>(
    FilterDate.CURRENT_MONTH,
  );
  const [rangeDate, setRangeDate] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const [transactionsData, setTransactionsData] = useState<
    ClientTransactionDatum[]
  >([]);
  const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
    ClientTransactionDatum[]
  >([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [typeTransactions, setTypeTransactions] =
    useState<TypeTransactions>('all');
  const [filterEntity, setFilterEntity] = useState<string[] | undefined>(
    undefined,
  );
  const [filterAccount, setFilterAccount] = useState<string[] | undefined>(
    undefined,
  );
  const [filterAssetClass, setFilterAssetClass] = useState<
    string[] | undefined
  >(undefined);

  const [filterCategory, setFilterCategory] = useState<string[] | undefined>(
    undefined,
  );

  const { beginDate, endDate } = getBeginDateAndEndDate(filterDate, rangeDate);

  const clientTransactions = useClientTransactions(
    selectedPortfolio?.id,
    beginDate,
    endDate,
  );

  useEffect(() => {
    if (selectedPortfolio?.id && clientTransactions.data) {
      setTransactionsData([...clientTransactions.data].sort(compare));
      setLoading(false);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, selectedPortfolio, clientTransactions.data]);

  useEffect(() => {
    let dataFiltered = transactionsData;

    dataFiltered = applyTypeFilter(dataFiltered, typeTransactions);
    dataFiltered = applySearchFilter(dataFiltered, searchValue);

    setTransactionsDataFiltered([...dataFiltered.sort(compare)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsData, typeTransactions, searchValue]);

  useEffect(() => {
    let dataFiltered = transactionsDataFiltered;

    dataFiltered = applyEntityFilter(dataFiltered, filterEntity);
    dataFiltered = applyAccountFilter(dataFiltered, filterAccount);
    dataFiltered = applyAssetClassFilter(dataFiltered, filterAssetClass);
    dataFiltered = applyCategoryFilter(dataFiltered, filterCategory);

    setTransactionsDataFiltered([...dataFiltered.sort(compare)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEntity, filterAccount, filterAssetClass, filterCategory]);

  const onChangeSearch = useCallback(
    (newValue: string | undefined) => {
      if (newValue === '' || newValue === undefined) {
        setSearchValue(undefined);
      } else {
        setSearchValue(newValue);
      }
    },
    [setSearchValue],
  );

  const onChangeFilteringEntity = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterEntity(undefined);
      } else {
        setFilterEntity(newValue);
      }
    },
    [setFilterEntity],
  );

  const onChangeFilteringAccount = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterAccount(undefined);
      } else {
        setFilterAccount(newValue);
      }
    },
    [setFilterAccount],
  );

  const onChangeFilteringAssetClass = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterAssetClass(undefined);
      } else {
        setFilterAssetClass(newValue);
      }
    },
    [setFilterAssetClass],
  );

  const onChangeFilteringCategory = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterCategory(undefined);
      } else {
        setFilterCategory(newValue);
      }
    },
    [setFilterCategory],
  );

  const onChangeTypeTransactions = (value: TypeTransactions) => {
    setTypeTransactions(value);
  };

  const onChangeFilterDate = (value: FilterDate) => {
    setFilterDate(value);
    setRangeDate([undefined, undefined]);
  };

  const onChangeDateRange = (value: [Date | undefined, Date | undefined]) => {
    setFilterDate(FilterDate.CUSTOM_DATE);
    setRangeDate(value);
  };

  const onClearDateRange = () => {
    setFilterDate(FilterDate.CURRENT_MONTH);
    setRangeDate([undefined, undefined]);
  };

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: 'Date',
      keyOrderAsc: 'dateASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Asset',
      keyOrderAsc: 'assetASC',
      keyOrderDesc: 'assetDESC',
    },
    {
      titleColumn: 'Entity',
      keyOrderAsc: 'entityASC',
      keyOrderDesc: 'entityDESC',
    },
    {
      titleColumn: 'Account',
      keyOrderAsc: 'accountASC',
      keyOrderDesc: 'accountDESC',
    },
    {
      titleColumn: 'Description',
      keyOrderAsc: 'descriptionASC',
      keyOrderDesc: 'descriptionDESC',
    },
    {
      titleColumn: 'Amount',
      keyOrderAsc: 'amountASC',
      keyOrderDesc: 'amountDESC',
    },
  ];

  useEffect(() => {
    setTransactionsDataFiltered([...transactionsDataFiltered].sort(compare));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSorting]);

  return {
    transactionsDataFiltered,
    searchValue,
    filterEntity,
    filterAccount,
    filterAssetClass,
    filterCategory,
    typeTransactions,
    filterDate,
    rangeDate,
    minTransactionDate,
    entitiesOptions: getEntitiesOptions(transactionsData),
    accountsOptions: getAccountsOptions(transactionsData),
    assetsClassOptions: getAssetsClassOptions(transactionsData),
    categoryOptions: getCategoriesOptions(transactionsData),
    handle: {
      onChangeSearch,
      onChangeFilteringEntity,
      onChangeFilteringAccount,
      onChangeFilteringAssetClass,
      onChangeFilteringCategory,
      onChangeTypeTransactions,
      onChangeFilterDate,
      onChangeDateRange,
      onClearDateRange,
      onChangeSorting,
    },
    headerColumns,
    currentSorting,
    loading,
  };
}
